<template>
	<div id="homeData">
		<webHeader></webHeader>

		<div class="loginVerify">
			<img src="../assets/images/img.png" alt="">
			<div class="verify">
				<p>请输入验证码</p>
				<el-input v-model="input" placeholder="请在此输入验证码" maxlength='6'></el-input>
				<el-button type="primary" @click="submit()">提交</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		verifyCode
	} from '@/request/api.js';
	import webHeader from '@/components/header.vue'
	export default {
		name: 'homeData',
		data() {
			return {
				input: ''
			}
		},
		components: {
			webHeader
		},
		created() {},
		mounted() {
			// console.log(this.$route.query)
			this.input = this.$route.query.code;
			localStorage.setItem('token', this.$route.query.token);
		},
		methods: {
			submit() {
				let data = {
					code: this.input
				}
				verifyCode(data).then(res => {
					if (res.code == 200) {
						this.$router.push({
							name: 'Form'
						})
					} else {
						this.$alert(res.msg, '提示', {
							confirmButtonText: '确定',
							type: 'warning',
							closeOnClickModal: true,
							closeOnPressEscape: true,
							center: true
						});
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	#homeData {
		width: 100vw;
		height: 100vh;

		.loginVerify {
			width: 100%;
			height: 100%;
			background: #0065FF;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				height: 500px;
			}

			.verify {
				width: 710px;
				height: 600px;
				border-radius: 15px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				padding: 100px;
				background-color: #343549;
				border: 1px solid #343549;
				box-shadow: 0 0 30px rgba(0, 0, 0, .2);

				p {
					font-size: 24px;
					text-align: center;
					color: #fff;
					margin-bottom: 36px;
				}

				/deep/.el-input {
					margin-bottom: 70px;
					background-color: #2F3044;
					border: none;

					.el-input__inner {
						background-color: transparent;
						border: none;
						color: #fff;
						text-align: center;
					}
				}

				.el-button {
					width: 100%;
					background-color: #2F2EE9;
					color: #fff;
					border: none;
					height: 46px;
					font-size: 16px;
				}
			}
		}
	}
</style>