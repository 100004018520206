import request from "./request.js";
import baseUrl from "./config.js"
// import qs from "qs";

// 示例
// export function login(params) {
// 	return request({
// 		url: baseUrl + "/sign/doctor/login",
// 		method: "post",
// 		data: params
// 	});
// }
// export function getMyMeetingList(params) {
// 	return request({
// 		url: baseUrl + "/sign/doctor/myMeeting",
// 		method: "get",
// 		params: params
// 	});
// }
// 不一定就是params，也可以是 query 还有 data 的呀！
// params是添加到url的请求字符串中的，用于get请求。会将参数加到 url后面。所以，传递的都是字符串。无法传递参数中含有json格式的数据
// data是添加到请求体（body）中的， 用于post请求。添加到请求体（body）中，json 格式也是可以的。
// }

// 验证身份
export function verifyCode(params) {
	return request({
		url: baseUrl + "/chat/doctor/verifyCode",
		method: "get",
		params: params
	});
}

// 提交文档
export function submitFile(params) {
	return request({
		url: baseUrl + "/chat/doctor/submitFile",
		method: "post",
		data: params
	});
}

// 提交网站链接 /chat/doctor/uploadUrl
export function uploadUrl(params) {
	return request({
		url: baseUrl + "/chat/doctor/uploadUrl",
		method: "post",
		data: params
	});
}